<template>
  <CFooter :fixed="false">
    <div>
      <span>Eclerk</span>
      <span class="ml-1">&copy; {{new Date().getFullYear()}}</span>
    </div>
    <div class="ml-auto">
      <a class="text-dark" href="https://www.facebook.com/AppEclerk" target="_blank"><CIcon name="cib-facebook" /></a>
      <a class="text-dark ml-4" href="https://www.linkedin.com/company/eclerk-app" target="_blank"><CIcon name="cib-linkedin" /></a>
    </div>

    <div class="ml-auto">
        <a href="mailto:contact@eclerk.io">Nous contacter</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
