<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <!--<CIcon name="logo" height="48" alt="Logo"/>-->
      <span class="font-dosis-title" style="font-size: 28px;">Eclerk</span>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <strong>{{companyName}}</strong>
      </CHeaderNavItem>
    </CHeaderNav>

    <CHeaderNav v-if="companyStripeSubscriptionIsTrial">
      <CHeaderNavItem v-if="nbDaysLeftTrial > 3"  placement="bottom-end" class="px-3" >
        <strong class="text-primary">Période d'essai - {{nbDaysLeftTrial}} jours restants </strong>
        <CButton class="ml-2" color="primary" @click="$router.push('/abonnement/souscription')">Je veux souscrire !</CButton>
      </CHeaderNavItem>

      <CHeaderNavItem v-else-if="nbDaysLeftTrial >=0" placement="bottom-end" class="px-3">
        <strong class="text-danger">Période d'essai - Attention, {{nbDaysLeftTrial}} jours restants </strong>
        <CButton class="ml-2" color="danger" @click="$router.push('/abonnement/souscription')">Je veux souscrire !</CButton>
      </CHeaderNavItem>

      <CHeaderNavItem v-else placement="bottom-end" class="px-3">
        <strong class="text-danger">Période d'essai expirée </strong>
        <CButton class="ml-2" color="danger" @click="$router.push('/abonnement/souscription')">Je veux souscrire !</CButton>
      </CHeaderNavItem>
    </CHeaderNav>

    <CHeaderNav>
      <CDropdown
        inNav
        class="c-header-nav-items"
        placement="bottom-end"
        add-menu-classes="pt-0"
      >
        <template #toggler>
          <CHeaderNavItem class="d-md-down-none mx-2" >
            <CHeaderNavLink>
                <strong>?</strong>
            </CHeaderNavLink>
          </CHeaderNavItem>
        </template>

        <CDropdownItem v-if="!userGroupContains(['CLIENT'])" to="/aide/presentation">
          <CIcon name="cil-settings" /> Aide générale
        </CDropdownItem>

        <CDropdownItem v-if="!userGroupContains(['CLIENT'])" to="/aide/role">
          <CIcon name="cil-user" /> Rôles des utilisateurs
        </CDropdownItem>


      </CDropdown>
    </CHeaderNav>

    <CHeaderNav>
      <CDropdown
        inNav
        class="c-header-nav-items"
        placement="bottom-end"
        add-menu-classes="pt-0"
      >
        <template #toggler>
          <CHeaderNavItem class="mx-2" >
            <CHeaderNavLink>
              <div @click="markAllNotificationsAsRead">
                <CIcon name="cil-bell"/>
                <CBadge v-if="nbUnreadNotifications > 0" color="danger" class="ml-auto">{{nbUnreadNotifications}}</CBadge>
              </div>
            </CHeaderNavLink>
          </CHeaderNavItem>
        </template>
        <CDropdownHeader
          tag="div"
          class="text-center"
          color="light"
        >
          <strong>Vous avez {{nbUnreadNotifications}} notifications non lues</strong>
        </CDropdownHeader>

        <CDropdownItem v-for="notification in notifications" v-bind:key="notification.id" @click="clickOnNotification(notification)">
          <div class="message" style="width: 100%;">
            <div>
              <small class="text-muted float-right">{{calculateDateDifference(notification.timestamp)}}</small>
            </div>
            <div v-bind:class="['text-truncated', (!notification.clicked) ? 'font-weight-bold' : 'text-muted']">
              <span v-if="!notification.clicked" class="dot mr-2"></span>{{notificationTitleRender(notification.verb)}}
            </div>
            <div class="text-truncated small text-muted mt-1">
              {{notificationDescriptionRender(notification)}}
            </div>
          </div>
        </CDropdownItem>
        <CDropdownDivider/>
        <CDropdownItem @click="$router.push('/notifications')">
          <strong>Voir toutes</strong>
        </CDropdownItem>

      </CDropdown>
    </CHeaderNav>

    <CHeaderNav class="mr-4">
      <CDropdown
        inNav
        class="c-header-nav-items"
        placement="bottom-end"
        add-menu-classes="pt-0"
      >
        <template #toggler>
          <CHeaderNavItem class="mx-2">
            <CHeaderNavLink>
              <CIcon name="cil-user" />
            </CHeaderNavLink>
          </CHeaderNavItem>
        </template>
        <CDropdownHeader
          tag="div"
          class="text-center"
          color="light"
        >
          <strong>Paramètres</strong>
        </CDropdownHeader>
        <CDropdownItem v-if="!userGroupContains(['CLIENT'])" to="/settings/profile-edition">
          <CIcon name="cil-settings" /> Modifier votre profil
        </CDropdownItem>
        <CDropdownItem to="/settings/modify-password">
            <CIcon name="cil-shield-alt"/> Modifier votre mot de passe
        </CDropdownItem>
        <CDropdownItem @click="logout">
          <CIcon name="cil-lock-locked" /> Se déconnecter
        </CDropdownItem>
      </CDropdown>
    </CHeaderNav>
    <!--<CSubheader class="px-3">

      <CBreadcrumb class="border-0 mb-0">
        <CLink @click="$router.go(-1)">Précédent</CLink>
      </CBreadcrumb>
    </CSubheader>-->
  </CHeader>
</template>

<script>
import userMixins from '@/mixins/userMixins'
import renderMixins from '@/mixins/renderMixins'
import notificationsMixins from '@/mixins/notificationsMixins'

import { APIUserConnected } from '@/api/APIUserConnected'

const apiUserConnected = new APIUserConnected()

export default {
  name: 'TheHeader',
  mixins: [userMixins, notificationsMixins, renderMixins],
  data () {
    const now = this.$dayjs()
    return {
      notifications: [],
      nbUnreadNotifications: 0,

      now: now
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    companyName () {
      return this.$store.state.user.companyName
    },
    companyStripeSubscriptionIsTrial () {
      if (this.$store.state.user.companyStripeSubscriptionIsTrial == true || this.$store.state.user.companyStripeSubscriptionIsTrial == 'true') {
        return true
      }
      return false
    },
    companyStripeSubscriptionTrialEnd () {
      return this.$store.state.user.companyStripeSubscriptionTrialEnd
    },

    nbDaysLeftTrial () {
      if (this.$store.state.user.companyStripeSubscriptionTrialEnd && this.$store.state.user.companyStripeSubscriptionTrialEnd != 0) {
        return this.differenceInDays((this.$dayjs.unix(this.$store.state.user.companyStripeSubscriptionTrialEnd) - this.now)/1000)
      }
      else {
        return ''
      }
    }
  },
  watch : {
    notifications: function (newNotifications) {
      this.nbUnreadNotifications = 0
      for (var i = 0; i < newNotifications.length; i++) {
        if (newNotifications[i].unread) {
          this.nbUnreadNotifications ++
        }
      }
    }
  },

  created: function () {
    if (this.token) {
      this.getTenLastNotifications()
    }
  },

  methods: {
   logout: function () {
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        this.$router.push('/pages/login')
      })
    },
    getTenLastNotifications () {
      apiUserConnected.getTenLastNotifications(this.token)
      .then((result) => {
          this.notifications  = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
    },

    markAllNotificationsAsRead () {
      apiUserConnected.markAllNotificationsAsRead(this.token)
      .then(() => {
        this.nbUnreadNotifications = 0
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
    },

    clickOnNotification (notification) {
      for (var i=0; i < this.notifications.length; i++) {
        if (this.notifications[i].id == notification.id) {
          this.notifications[i].clicked = true
        }
      }
      apiUserConnected.markNotificationAsClicked(this.token, notification.id)
      .then(() => {
        this.$router.push(this.notificationLinkRender(notification.actor, notification.verb))
      })
    },

    calculateDateDifference(notification_date) {
      return this.notificationDateRender((this.now - this.$dayjs(notification_date))/1000)
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
  .dot {
     height: 11px;
     width: 11px;
     background-color: #321fdb;
     border-radius: 50%;
     display: inline-block;
}
</style>
