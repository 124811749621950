<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand to="/">
      <!--<CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 556 134"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      />-->
      <div style="padding-top: 20px;">
        <logoComponent />
      </div>
      <!--<span class="font-dosis-title" style="font-size: 28px; font-weight: bold;">Eclerk</span>-->

    </CSidebarBrand>

    <!--<CRenderFunction flat :content-to-render="$options.nav"/>-->

    <CSidebarNav v-if="userGroupContains(['CLIENT'])">
      <CSidebarNavItem
        name="Votre tableau de bord" to="/"
        icon='cil-layers'>
      </CSidebarNavItem>
      <CSidebarNavItem
        name="Votre profil" to="/settings/client-profile-edition"
        icon='cil-settings'>
      </CSidebarNavItem>
    </CSidebarNav>


    <CSidebarNav v-else>
      <CSidebarNavItem
        name="Tableau de bord" to="/dashboard"
        icon='cil-layers'>
      </CSidebarNavItem>

      <CSidebarNavTitle>
        Votre Activité
      </CSidebarNavTitle>
      <CSidebarNavItem
        name="Missions en cours" to="/vos-missions"
        icon='cil-loop'>
      </CSidebarNavItem>
      <CSidebarNavItem
        name="Reporting journalier" to="/vos-taches"
        icon='cil-clock'>
      </CSidebarNavItem>


      <CSidebarNavTitle>
        Interlocution client
      </CSidebarNavTitle>

      <CSidebarNavDropdown
        name="Clients" icon="cil-contact">
        <CSidebarNavItem
          name="Clients du cabinet" to="/manage-clients" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
        <CSidebarNavItem
          name="Créer un client" to="/add-new-client" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
      </CSidebarNavDropdown>

      <CSidebarNavDropdown
        name="Contacts" icon="cil-address-book">
        <CSidebarNavItem
          name="Contacts du cabinet" to="/manage-contacts" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
        <CSidebarNavItem
          name="Ajouter un contact" to="/add-new-contact" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
      </CSidebarNavDropdown>


      <CSidebarNavTitle v-if="userGroupContains(['MANAGER'])">
        Gestion du cabinet
      </CSidebarNavTitle>
      <CSidebarNavItem
        v-if="userGroupContains(['MANAGER'])"
        name="Reporting" to="/manager-dashboard-tasks"
        icon='cil-speedometer'>
      </CSidebarNavItem>
      <CSidebarNavDropdown
        v-if="userGroupContains(['MANAGER'])"
        name="Devis, facture, paiement" icon="cil-euro">
        <CSidebarNavItem
          name="Devis" to="/manager-dashboard-devis" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
        <CSidebarNavItem
          name="Factures" to="/manager-dashboard-factures" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
        <CSidebarNavItem
          name="Paiements" to="/manager-dashboard-paiements" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
        <CSidebarNavItem
          name="Paiements en ligne" to="/manager-dashboard-paiements-en-ligne" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
      </CSidebarNavDropdown>

      <CSidebarNavDropdown
        v-if="userGroupContains(['MANAGER'])"
        name="Missions" icon="cil-copy">
        <CSidebarNavItem
          name="Toutes les missions" to="/manager-all-missions" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
        <CSidebarNavItem
          name="Créer une mission" to="/add-new-mission" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
      </CSidebarNavDropdown>


      <CSidebarNavTitle>
        Gestions administrative
      </CSidebarNavTitle>

      <CSidebarNavDropdown
        v-if="userGroupContains(['ADMIN']) && (companyStripeProductId === stripeSmallProductId || companyStripeProductId === stripeMediumProductId)"
        name="Collaborateurs" icon="cil-user">
        <CSidebarNavItem
          name="Vos collaborateurs" to="/administration/manage-collaborateurs" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
        <CSidebarNavItem
          name="Créer un collaborateur" to="/administration/add-collaborateur" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
      </CSidebarNavDropdown>

      <CSidebarNavDropdown
        v-if="userGroupContains(['ADMIN']) || userGroupContains(['BOSS'])"
        name="Paramètres" icon="cil-settings">
        <CSidebarNavItem
          v-if="userGroupContains(['ADMIN'])" addLinkClasses='subtitle-side-bar'
          name="Types de missions" to="/administration/manage-mission-types">
        </CSidebarNavItem>
        <CSidebarNavItem
          v-if="userGroupContains(['BOSS'])" addLinkClasses='subtitle-side-bar'
          name="Votre entreprise" to="/company">
        </CSidebarNavItem>
        <CSidebarNavItem
          v-if="userGroupContains(['BOSS'])" addLinkClasses='subtitle-side-bar'
          name="Convention d'honoraires B2B" to="/parametres/default-convention-honoraires-b2B">
        </CSidebarNavItem>
        <CSidebarNavItem
          v-if="userGroupContains(['BOSS'])" addLinkClasses='subtitle-side-bar'
          name="Convention d'honoraires B2C" to="/parametres/default-convention-honoraires-b2c">
        </CSidebarNavItem>
      </CSidebarNavDropdown>

      <CSidebarNavDropdown
        v-if="companyStripeProductId === stripeSmallProductId || companyStripeProductId === stripeMediumProductId"
        name="Logistique" icon="cil-3d">
        <CSidebarNavItem
          name="Courses" to="/logistique/courses" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
      </CSidebarNavDropdown>

      <CSidebarNavDropdown v-if="userGroupContains(['BOSS'])"
        name="Votre abonnement" icon="cil-cart">
        <CSidebarNavItem
          name="Vos factures" to="/abonnement/vos-factures" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
        <CSidebarNavItem
          name="Vos moyens de paiement" to="/abonnement/vos-moyens-de-paiement" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
        <CSidebarNavItem
          name="Résiliation" to="/abonnement/resiliation" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
      </CSidebarNavDropdown>


      <CSidebarNavTitle v-if="userGroupContains(['MANAGER'])">
        Autres
      </CSidebarNavTitle>


      <CSidebarNavDropdown
        v-if="userGroupContains(['MANAGER'])"
        name="Archives" icon="cil-folder">
        <CSidebarNavItem
          name="Clients archivés" to="/archives/clients" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
        <CSidebarNavItem
          name="Contacts archivés" to="/archives/contacts" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
        <CSidebarNavItem
          name="Missions archivées" to="/archives/missions" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>
        <CSidebarNavItem
          name="Factures archivées" to="/archives/factures" addLinkClasses='subtitle-side-bar'>
        </CSidebarNavItem>

      </CSidebarNavDropdown>

    </CSidebarNav>

    <CSidebarBrand class="d-md-down-none text-center">
      <span v-if="companyLogo">
        <img :src="baseUrl + companyLogo" class="mt-2" style="width: 70%; max-width: 200px;"/>
      </span>
      <span v-else>
        {{companyName}}
      </span>
    </CSidebarBrand>

    <!--<CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])">
      Mayer
    </CSidebarMinimizer>-->
  </CSidebar>
</template>

<script>
import { apiBaseUrl, stripeSoloProduct, stripeSmallProduct, stripeMediumProduct } from '@/variables/localVariables'
import userMixins from '@/mixins/userMixins'

import logoComponent from '@/components/logoComponent'

export default {
  name: 'TheSidebar',
  components: {
    logoComponent
  },
  mixins: [userMixins],
  data () {
    return  {
      baseUrl: apiBaseUrl,
      stripeSoloProductId: stripeSoloProduct,
      stripeSmallProductId: stripeSmallProduct,
      stripeMediumProductId: stripeMediumProduct
    }
  },
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    },
    companyName () {
      return this.$store.state.user.companyName
    },
    companyLogo () {
      return this.$store.state.user.companyLogo
    },

    companyStripeProductId () {
      return this.$store.state.user.companyStripeProductId
    }
  },
}
</script>
<style>
.subtitle-side-bar {
  font-size: 0.9em;
}
</style>
